<template>
    <div class="s-modal">
        <h2>Orte</h2>
        <div class="liste">
            <h6>Ort hinzufügen:</h6>
            <div class="list-item">

                <div class="s-col">
                    <input type="text" v-model="newstadt" placeholder="Name der Stadt" class="form-control">
                </div>
                <div class="s-col">
                    <input type="text" v-model="newvort" placeholder="Name des Veranstaltungsortes" class="form-control">
                </div>
                <div class="s-col">
                    <input type="text" v-model="newadresse" placeholder="Name der Adresse" class="form-control">
                </div>
            </div>
            <button @click="addOrt" class="btn btn-outline-primary" 
                    :disabled="this.newstadt == '' || this.newvort == '' || this.newadresse == ''">
                        Hinzufügen
            </button>
        </div>
        <div class="liste">
            <ort-item v-for="ort in orte" :key="ort.id" :ort="ort" @delOrt="getOrte()"></ort-item>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import OrtItem from './OrtItem.vue'
export default {
    name: 'ModalOrte',
    components: {
        OrtItem
    },
    data: () => ({
        orte: [],
        newvort: '',
        newstadt: '',
        newadresse: ''
    }),
    mounted() {
        this.getOrte()
    },
    methods: {
        addOrt() {
            if (this.newstadt !== '' && this.newvort !== '' && this.newadresse !== '') {
            // let newort = { stadt: this.newstadt, vort: this.newvort, adresse: this.newadresse, id: 4 }
            // this.orte.push(newort)

                axios.post('/login.php?editor=seminarverwaltung&rest=orte', {
                    stadt: this.newstadt,
                    ort: this.newvort,
                    strasse: this.newadresse
                })
                .then(response => {
                    console.log(response)
                    this.newstadt = this.newvort = this.newadresse = ''
                    this.getOrte()
                    // this.orte = response.data
                })
            }
        },
        getOrte() {
            axios.get('/login.php?editor=seminarverwaltung&rest=orte')
                .then(response => {
                    //  console.log(response.data)
                    this.orte = response.data
                })
        }
    }
}
</script>

<style lang="scss">
.liste {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    // margin: 0 -5px;
    button {
        align-self: flex-start
    }
    .list-item {
        &.hover:hover {
            background-color: gray;
        }
        display: flex;
        margin: 0 -10px;
        // padding: 5px;
        .s-col {
            padding: 10px;
            flex-basis: 30%;
            input {
                width: 100%;
            }
        }
        .s-col-auto {
            padding: 10px;
            flex-basis: 10%;
            text-align: right;
        }
    }
}
</style>
